<nav class="navbar navbar-expand-lg navColor">
  <div class="container-fluid" style="margin-left: 70px">
    <img src="..\..\assets\logo.png" style="height: 40px; position: absolute; left: 2px; top: 5px" alt="" />
    <a class="navbar-brand brand" href="https://gmptoday.com/">Learn & Invest</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02"
      aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" href="https://gmptoday.com">GMP Today</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle active" href="#" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            Investments
          </a>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item" href="https://gmptoday.com/investment/1/gold-bonds-23">Gold Bonds</a>
            </li>
            <!-- <li><a class="dropdown-item" href="#">Another action</a></li>
                      <li><hr class="dropdown-divider"></li>
                      <li><a class="dropdown-item" href="#">Something else here</a></li> -->
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="https://gmptoday.com/incometaxreturn/package.html">Income
            Tax Return</a>
        </li>

        <li class="nav-item">
          <a class="nav-link active" href="https://gmptoday.com/incometaxreturn/aboutus.html">About Us</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="container marginLess addPadding">
  <div class="row">
    <div class="col-md-8 col-sm-12">
      <div class="introduction">
        Welcome to <strong>GMPToday</strong>, part of
        <strong>Learn & Invest</strong>. Here, in GMPToday we will provide all
        details about
        <strong>Upcoming IPO, Grey Market premium Updates, Live IPO
          Subscription.</strong>
        You can also calculate expected Listing gains by mentioning number of
        lots you want to buy. Visit here regularly to get all the latest
        updates.
      </div>
      <hr />

      <marquee behavior="" direction="left" height="32">

        <!-- <a class="marqueeIpo" href="https://gmptoday.com/ipo/159/index.html">
          Suraksha Diagnostic <span class="closedStatus blink_me">Closed</span>
        </a>
        &nbsp; &nbsp;&nbsp;&nbsp;
        <a class="marqueeIpo" href="https://gmptoday.com/ipo/161/index.html">
          Vishal Mega Mart<span class="Upcoming blink_me"> Upcoming</span>
        </a>
        &nbsp; &nbsp;&nbsp;&nbsp;
        <a class="marqueeIpo" href="https://gmptoday.com/ipo/162/index.html">
          Sai Life IPO <span class="Upcoming blink_me"> Upcoming</span>
        </a>
        &nbsp; &nbsp;&nbsp;&nbsp;
        <a class="marqueeIpo" href="https://gmptoday.com/ipo/163/index.html">
          One Mobikwik <span class="Upcoming blink_me"> Upcoming</span>
        </a>
        &nbsp; &nbsp;&nbsp;&nbsp; -->
        <p class="maintain">Website is Under Maintenance. Sorry for the inconvenience.</p>
      </marquee>
      <div>
        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-md-3 text-center vl">
                <h5>Important Links</h5>
              </div>

              <div class="col-md-3 text-center vl">
                <a class="impLinks" href="#openIPO">Trending IPO's</a>
              </div>

              <div class="col-md-3 text-center vl">
                <a class="impLinks" href="#upcomingIPO"> Upcoming IPO's </a>
              </div>

              <div class="col-md-3 text-center vl">
                <a class="impLinks" href="#listedIPO"> Listed IPO's</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <div class="card upcomingIPO">
        <div class="card-header">
          <h4>Mainline Trending IPO 2022</h4>
        </div>
        <div class="card-body">
          <table class="table">
            <tbody>

              <tr>
                <td>
                  <a href="https://gmptoday.com/ipo/159/index.html">Suraksha Diagnostic
                  </a>
                  <span class="closedStatus blink_me">
                    Closed</span>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://gmptoday.com/ipo/161/index.html"> Vishal Mega Mart
                  </a>
                  <span class="Upcoming blink_me">
                    Upcoming </span>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://gmptoday.com/ipo/162/index.html"> Sai Life IPO
                  </a>
                  <span class="Upcoming blink_me">
                    Upcoming</span>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://gmptoday.com/ipo/163/index.html">One Mobikwik
                  </a>
                  <span class="Upcoming blink_me">
                    Upcoming</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <hr />
</div>

<div id="openIPO" *ngIf="screenWidth > 768">
  <div class="container marginLess">
    <h2 class="headingsTable">
      Trending IPO :<i class="fa-solid fa-envelope-open-dollar"></i>
    </h2>
    <div *ngIf="activeIpoList.length == 0" class="row">
      <div class="col-md-12 alert alert-primary">
        <h4>No Trending IPO, Keep Checking</h4>
      </div>
    </div>
    <div *ngIf="activeIpoList.length > 0" class="row">
      <div class="col-md-12">
        <table class="table table-bordered table-hover">
          <thead class="tableColor">
            <tr>
              <th class="text-center">IPO</th>
              <th class="text-center">GMP Latest</th>
              <th class="text-center">IPO Price</th>
              <th class="text-center">Estimated Listing</th>
              <th class="text-center">IPO status</th>
              <th class="text-center">IPO Open Date</th>
              <th class="text-center">IPO Close Date</th>
              <th class="text-center">Listing Date</th>
              <th class="text-center">GMP Updated On</th>

              <th class="text-center">Expected Listing Gains</th>
            </tr>
          </thead>
          <tbody class="table-group-divider">
            <tr *ngFor="let ipo of activeIpoList">
              <td class="text-center">
                <a href="https://gmptoday.com/ipo/{{ ipo.id }}/index.html">{{ ipo.ipoName }}</a>
              </td>
              <td class="text-center">
                {{
                ipo.gmpAvailable
                ? (ipo.ipoGmp | currency: "INR":"symbol")
                : "N.A."
                }}
                <i *ngIf="ipo.gmpChange == 'increase' && ipo.gmpAvailable" class="fa-solid fa-arrow-up increase"></i>
                <i *ngIf="ipo.gmpChange == 'decrease' && ipo.gmpAvailable" class="fa-solid fa-arrow-down decrease"></i>
              </td>

              <td class="text-center">
                {{
                ipo.ipoPrice > 0
                ? (ipo.ipoPrice | currency: "INR":"symbol")
                : "N.A."
                }}
              </td>
              <td class="text-center">
                {{
                ipo.gmpAvailable && ipo.ipoPrice > 0
                ? (estimatedListingPrice(ipo) | currency: "INR":"symbol")
                : "N.A."
                }}
                <div>
                  <span *ngIf="ipo.gmpAvailable && ipo.ipoPrice > 0">(</span>
                  <span>{{
                    ipo.gmpAvailable && ipo.ipoPrice > 0
                    ? (percentCalculate(ipo) | number: "1.1-2")
                    : ""
                    }}</span><span *ngIf="ipo.gmpAvailable && ipo.ipoPrice > 0">%</span><span
                    *ngIf="ipo.gmpAvailable && ipo.ipoPrice > 0">)</span>
                </div>
              </td>
              <td class="text-center">
                <span [ngClass]="{
                    ' openStatus blink_me': ipo.ipoStatus === 'Open',
                    closedStatus: ipo.ipoStatus === 'Closed',
                    listedStatus: ipo.ipoStatus === 'Listed',
                    Upcoming: ipo.ipoStatus === 'Upcoming'
                  }">
                  {{ ipo.ipoStatus }}</span>
              </td>
              <td class="text-center dateSize">
                {{ ipo.ipoOpenDate | date: "dd-MMM-y" }}
              </td>
              <td class="text-center dateSize">
                {{ ipo.ipoCloseDate | date: "dd-MMM-y" }}
              </td>
              <td class="text-center dateSize">
                {{ ipo.ipoListDate | date: "dd-MMM-y" }}
              </td>
              <td class="text-center dateSize">
                {{
                ipo.gmpAvailable
                ? (ipo.ipoGmpLastUpdated | date: "dd-MMM-y")
                : "N.A."
                }}
              </td>
              <td class="text-center">
                <button type="button" [disabled]="!ipo.gmpAvailable || ipo.ipoPrice == 0" class="btn btn-primary"
                  data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="assignIpoToModal(ipo)">
                  Check
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ ipoInModal?.ipoName }}
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div>
              <label for="exampleInputEmail1" class="form-label">Number of lots you want to buy</label>
              <input [(ngModel)]="noOfLots" type="number" class="form-control" id="exampleInputEmail1" min="1"
                max="{{ ipoInModal?.maxLot }}" oninput="validity.valid||(value=1);" />
              <div class="form-text">
                Minimum lot is <span>1,</span> Maximum lots is
                <span>{{ ipoInModal?.maxLot }}</span>
              </div>
            </div>
            <div>
              <br />
              <table class="table table-bordered">
                <thead class="">
                  <tr>
                    <th>Lots Applied</th>
                    <th>Amount of 1 lot</th>
                    <th>Total Amount</th>
                    <th>Return</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ noOfLots }}</td>
                    <td>{{ amountOfOneLot() | currency: "INR":"symbol" }}</td>
                    <td>{{ totalInvested() | currency: "INR":"symbol" }}</td>
                    <td>
                      {{
                      calculateExpectedGain()
                      | currency: "INR":"symbol":"1.0-0"
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <h6>
                Expected <span *ngIf=" calculateExpectedGain() - totalInvested() >0" class="gain">Gain {{
                  calculateExpectedGain() - totalInvested()
                  | currency: "INR":"symbol":"1.0-0"
                  }}</span> <span *ngIf=" calculateExpectedGain() - totalInvested() < 0" class="loss">Loss {{
                  calculateExpectedGain() - totalInvested()
                  | currency: "INR":"symbol":"1.0-0"
                  }}</span>
                <span *ngIf=" calculateExpectedGain() - totalInvested() == 0" class="gain">
                  No Gain Or Loss
                </span>
              </h6>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="openIPO" *ngIf="screenWidth <= 768">
  <div class="container">
    <div class="row">
      <h3 class="headingsTable">Trending:</h3>
      <div *ngIf="activeIpoList.length == 0">
        <div class="col-md-12 alert alert-primary">
          <h5>No Trending IPO, Keep Checking</h5>
        </div>
      </div>
      <div *ngIf="activeIpoList.length > 0">
        <div class="col-sm-12" *ngFor="let ipo of activeIpoList">
          <div class="card" style="width: 22rem">
            <h5 class="card-header">
              <a href="https://gmptoday.com/ipo/{{ ipo.id }}/index.html">{{ ipo.ipoName }}</a>
            </h5>
            <div class="card-body">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>GMP</td>
                    <td>
                      {{
                      ipo.gmpAvailable
                      ? (ipo.ipoGmp | currency: "INR":"symbol")
                      : "N.A."
                      }}
                      <i *ngIf="ipo.gmpChange == 'increase' && ipo.gmpAvailable"
                        class="fa-solid fa-arrow-up increase"></i>
                      <i *ngIf="
                          ipo.gmpChange == 'decrease' && ipo.gmpAvailable 
                        " class="fa-solid fa-arrow-down decrease"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>IPO Price</td>
                    <td>
                      {{
                      ipo.ipoPrice > 0
                      ? (ipo.ipoPrice | currency: "INR":"symbol")
                      : "N.A."
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>Estimated Listing Price</td>

                    <td>
                      {{
                      ipo.gmpAvailable && ipo.ipoPrice > 0
                      ? (estimatedListingPrice(ipo)
                      | currency: "INR":"symbol")
                      : "N.A."
                      }}
                      <div>
                        <span *ngIf="ipo.gmpAvailable && ipo.ipoPrice > 0">(</span>
                        <span>{{
                          ipo.gmpAvailable && ipo.ipoPrice > 0
                          ? (percentCalculate(ipo) | number: "1.1-2")
                          : ""
                          }}</span><span *ngIf="ipo.gmpAvailable && ipo.ipoPrice > 0">%</span><span
                          *ngIf="ipo.gmpAvailable && ipo.ipoPrice > 0">)</span>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>IPO Status</td>
                    <td>
                      <span [ngClass]="{
                          ' openStatus blink_me': ipo.ipoStatus === 'Open',
                          closedStatus: ipo.ipoStatus === 'Closed',
                          listedStatus: ipo.ipoStatus === 'Listed',
                          Upcoming: ipo.ipoStatus === 'Upcoming'
                        }">
                        {{ ipo.ipoStatus }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>IPO Open Date</td>
                    <td>{{ ipo.ipoOpenDate | date: "dd-MMM-y" }}</td>
                  </tr>
                  <tr>
                    <td>IPO Close Date</td>
                    <td>{{ ipo.ipoCloseDate | date: "dd-MMM-y" }}</td>
                  </tr>
                  <tr>
                    <td>IPO Listing Date</td>
                    <td>{{ ipo.ipoListDate | date: "dd-MMM-y" }}</td>
                  </tr>
                  <tr>
                    <td>GMP Updated On</td>
                    <td>
                      {{
                      ipo.gmpAvailable
                      ? (ipo.ipoGmpLastUpdated | date: "dd-MMM-y")
                      : "N.A."
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal"
                (click)="assignIpoToModal(ipo)" [disabled]="!ipo.gmpAvailable || ipo.ipoPrice == 0">
                Expected Listing Gains
              </button>
            </div>
          </div>
          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ ipoInModal?.ipoName }}
                  </h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div>
                    <label for="exampleInputEmail1" class="form-label">Number of lots you want to buy</label>
                    <input [(ngModel)]="noOfLots" type="number" class="form-control" id="exampleInputEmail1" min="1"
                      max="{{ ipoInModal?.maxLot }}" oninput="validity.valid||(value=1);" />
                    <div class="form-text">
                      Minimum lot is <span>1,</span> Maximum lots is
                      <span>{{ ipoInModal?.maxLot }}</span>
                    </div>
                  </div>
                  <div>
                    <br />
                    <table class="table table-bordered">
                      <thead class="">
                        <tr>
                          <th>Lots Applied</th>
                          <th>Amount of 1 lot</th>
                          <th>Total Amount</th>
                          <th>Return</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ noOfLots }}</td>
                          <td>
                            {{ amountOfOneLot() | currency: "INR":"symbol" }}
                          </td>
                          <td>
                            {{ totalInvested() | currency: "INR":"symbol" }}
                          </td>
                          <td>
                            {{
                            calculateExpectedGain()
                            | currency: "INR":"symbol":"1.0-0"
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <h6>

                      Expected <span *ngIf=" calculateExpectedGain() - totalInvested() >0" class="gain">Gain {{
                        calculateExpectedGain() - totalInvested()
                        | currency: "INR":"symbol":"1.0-0"
                        }}</span> <span *ngIf=" calculateExpectedGain() - totalInvested() < 0" class="loss">Loss {{
                        calculateExpectedGain() - totalInvested()
                        | currency: "INR":"symbol":"1.0-0"
                        }}</span>
                      <span *ngIf=" calculateExpectedGain() - totalInvested() == 0" class="gain">
                        No Gain or Loss
                      </span>

                    </h6>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container marginLess">
  <hr />
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <h3 class="headingsTable" id="upcomingIPO">Upcoming IPO :</h3>
      <table class="table table-bordered table-hover">
        <thead class="tableColor">
          <tr>
            <th class="text-center">Company</th>
            <th class="text-center">Open/ Close Dates</th>
            <th class="text-center">Price Range</th>
          </tr>
        </thead>
        <tbody class="table-group-divider">
          <tr *ngFor="let ipo of upcomingIpoList">
            <td class="text-center">{{ ipo.ipoName }}</td>
            <td class="text-center">{{ ipo.ipoOpenDate }}</td>
            <td class="text-center">{{ ipo.ipoPriceRange }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <h3 class="headingsTable" id="listedIPO">Recently Listed IPO:</h3>
      <table class="table table-bordered table-hover">
        <thead class="tableColor">
          <tr>
            <th class="text-center">Company</th>
            <th class="text-center">Listing date</th>
            <th class="text-center">Price Range</th>
            <th class="text-center">Listed at</th>
            <th class="text-center">Gain/Loss</th>
          </tr>
        </thead>
        <tbody class="table-group-divider">
          <tr *ngFor="let ipo of listedIpoList">
            <td class="text-center">{{ ipo.ipoName }}</td>
            <td class="text-center">
              {{ ipo.ipoListDate | date: "dd-MMM-y" }}
            </td>
            <td class="text-center">{{ ipo.ipoPriceRange }}</td>
            <td class="text-center">
              {{ ipo.listingPrice | currency: "INR":"symbol" }}
            </td>
            <td class="text-center" [ngClass]="{
                'text-success': ipo.listingGain.slice(0, 1) === '+',
                'text-danger': ipo.listingGain.slice(0, 1) === '-'
              }">
              {{ ipo.listingGain }}
              <div>
                <a class="drhpLink" target="_blank" href="{{ ipo.drhpLink }}">
                  Offer Doc
                  <i class="fa-solid fa-up-right-from-square fa-2xs"></i></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="container marginLess">
  <hr />
  <h2>FAQ's</h2>
  <div class="row faq">
    <div class="col-md-8 col-12">
      <h4>What is IPO ?</h4>
      <p>
        IPO stands for Initial Public Offering. It is the process in which a
        Privately held company is transformed into a Public Company. An IPO is
        the first offering of shares to the public. IPO also acts as an
        opportunity for investors to earn returns by investing in IPO. Buy
        issuing IPO, company infuses fresh equity money into the business. They
        also raise funds for the future through IPO.
      </p>
    </div>
    <div class="col-md-4 col-12">
      <iframe width="340" height="250" src="https://www.youtube.com/embed/WWall_VMCX8" title="YouTube video player"
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>
  </div>
  <br />

  <div class="row faq">
    <div class="col-md-8 col-12">
      <h4>What are the different types of IPOs ?</h4>
      <p>There are two types of IPO:</p>
      <ol>
        <li>
          <strong>Fixed Price Issue:</strong> In Fixed price issue, the price of
          offerings is evaluated by considering companies assets, liabilities.
          After this a fixed price is calculated for the offerings . As, fixed
          price is mostly kept lower than the market price, investors are always
          interested in Fixed Price Issue.
        </li>
        <li>
          <strong> Book Building Issue:</strong> In book building issue, price
          band or range is set for the offerings. The lower price range is
          called as ‘floor price’ and higher price range is called ‘cap price’.
          You can bid with your desired price.
        </li>
      </ol>
      <p>
        An IPO can be done through Fixed Price Issue or book building issue or
        combination of both.
      </p>
    </div>
  </div>
  <br />
  <div class="row faq">
    <div class="col-md-8 col-12">
      <h4>What is GMP in an IPO ?</h4>
      <p>
        GMP or Grey Market Premium, is used in stock market to check the
        estimated listing price of an IPO. Grey Market is unofficial and is not
        regulated by SEBI or any other organization. Investors look at the GMP
        of an IPO closely to estimate the listing gains. The Grey market
        operates between IPO announcement day till the IPO listing day. Higher
        the demand of IPO, higher is the GMP.
      </p>
    </div>
    <div class="col-md-4 col-12">
      <iframe width="340" height="250" src="https://www.youtube.com/embed/PMAvFzFEzzY" title="YouTube video player"
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>
  </div>
  <br />
  <div class="row faq">
    <div class="col-md-8 col-12">
      <h4>How to invest in an IPO online</h4>
      <p>
        You can apply for an IPO online via ASBA (Application Supported by
        Blocked Amount) facility available with most banks or UPI. You need
        three accounts to invest in an IPO
      </p>
      <ol>
        <li>
          <strong>Demat Account :</strong> The shares will be stored in Demat
          account in an electronic form. It is mandatory to have Demat account
          to apply in an IPO.
        </li>
        <li>
          <strong>Bank Account:</strong> For initiating payment for the IPO.
          Payment is done by UPI or ASBA facility available with most banks.
        </li>
        <li>
          <strong> Trading Account: </strong> You can open this with any
          brokerage firm offering the facility of stock trading.
        </li>
      </ol>
    </div>
    <div class="col-md-4 col-12"></div>
  </div>
  <br />
  <div class="row faq">
    <div class="col-md-8 col-12">
      <h4>Who can Invest in IPO ?</h4>
      <p>Investors can be divided into three main categories</p>
      <ol>
        <li>
          <strong>Qualified institutional buyers (QIB): </strong>
          Comprises of Mutual funds, Banks, Financial institutions, Insurance
          companies, Foreign institutional investors and others .
        </li>
        <li>
          <strong> Non Institutional investors (NII): </strong> Comprises of
          Corporates, Individual other than retail investors.
        </li>
        <li>
          <strong>Retail Individual Investors </strong>Comprises of Individual
          Investors who bid for amount not more than 2 Lakhs.
        </li>
      </ol>
    </div>
    <div class="col-md-4 col-12"></div>
  </div>
  <br />
</div>
<div class="bg-light py-4">
  <div class="container">
    <footer>
      <div class="row">
        <div class="col-md-2 col-2">Contact:</div>
        <div class="col-md-1 col-1">
          <a class="fb" href="https://www.facebook.com/shekhar.dahiya1" target="_blank">
            <i class="fa-brands fa-facebook"></i>
          </a>
        </div>
        <div class="col-md-1 col-1">
          <a class="youtube" href="https://www.youtube.com/channel/UCpVe5Iyg2RZ20V5CzpiOyMQ" target="_blank">
            <i class="fa-brands fa-youtube"></i>
          </a>
        </div>
        <div class="col-md-1 col-1">
          <a class="linkedin" href="https://www.linkedin.com/in/shekhar-dahiya/" target="_blank">
            <i class="fa-brands fa-linkedin"></i>
          </a>
        </div>
        <!-- <div class="col-md-2 col-6">
          <a class="call" href="8225884946">
            <i class="fa-solid fa-phone"></i>
            +91-8225884946
          </a>
        </div> -->
      </div>
    </footer>
  </div>
</div>